'use strict';

import {Editor} from './ckeditor';

export default class Comment {
    initialize(editor) {
        let jsComment = document.querySelector('.js-comment');
        if (jsComment) {
            this.initializeElement(editor, jsComment);
        }
    }

    initializeElement(editor, element) {
        let instance = this;
        let form = element.querySelector('form');

        if (!editor) {
            editor = new Editor();
        }
        let editorInstance = editor.initializeEditor(form.querySelector('.editor-comment'), 'minimal', {
            characterMax: 1000
        });

        form.addEventListener('submit', () => {
            return false;
        });

        let csrfToken = form.dataset.hasOwnProperty('token') ? form.dataset.token : '';
        let path = form.dataset.hasOwnProperty('path') ? form.dataset.path : '';
        let extId = parseInt(form.dataset.hasOwnProperty('id') ? form.dataset.id : 0, 10);
        let buttonSubmit = form.querySelector('button[type=submit]');
        buttonSubmit.addEventListener('click', (event) => {
            event.preventDefault();
            instance.addComment(element, buttonSubmit, path, extId, editorInstance, csrfToken);
        });
    }

    async addComment(element, buttonSubmit, url, extId, editorInstance, csrfToken) {
        let instance = this;
        buttonSubmit.disabled = true;

        let message = await editorInstance.then((editor) => {
            return editor.getData();
        });

        let data = {
            _token: csrfToken,
            extId: extId,
            comment: message
        };
        fetch(url, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk Antwort war nicht in Ordnung!');
            }
            return response.json();
        }).then(data => {
            if (!(data.hasOwnProperty('error') && data.error > 0)) {
                editorInstance.then((editor) => {
                    return editor.setData('');
                });
                toastr.success('Kommentar gespeichert');
                instance.updateShow(element, url, extId);
            } else {
                throw new Error('Es ist ein unbekannter Fehler aufgetreten!');
            }
        }).catch(error => {
            toastr.error('Kommentar wurde nicht hinzugefügt!<br>' + error.toString(), '', {'timeOut': 0});
            console.error('Error:', error);
        }).finally(() => {
            buttonSubmit.disabled = false;
        });
    }

    updateShow(element, url, extId) {
        let instance = this;
        let show = element.querySelector('.show-comment');

        if (show) {
            fetch(url + '/show?extId=' + extId, {
                method: 'GET',
                headers: {
                    'Accept': 'text/html',
                },
            }).then(response => response.text()).then(data => {
                show.innerHTML = data;
                instance.scrollToComment(element);
            });
        }
    }

    scrollToComment(element, extId) {
        let scrollTop = 0;
        let scrollElement = window;

        // Get extId from new comment
        if (typeof extId === 'undefined') {
            let nodes = element.querySelectorAll('.show-comment .comment');
            extId = nodes[nodes.length - 1].getAttribute('data-id');
            extId = parseInt(extId, 10);
        }

        // Find comment and scroll
        let commentItem = element.querySelector('.show-comment .comment-' + extId);
        if (commentItem) {
            scrollTop = commentItem.offsetTop - 70; // top - headerNavigation

            let fancyboxSlideElement = commentItem.closest('.fancybox__slide');
            if (fancyboxSlideElement) {
                scrollElement = fancyboxSlideElement;
                scrollTop = commentItem.offsetTop;
            }

            scrollElement.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    }
}
